export const FACEBOOK_LINK = 'https://www.facebook.com/orientujsiekuchniaazjatycka';
export const INSTAGRAM_LINK = 'https://www.instagram.com/orientujsie';
export const PYSZNE_LINK = 'https://www.pyszne.pl/orientuj-sie-wroclaw';

export const UP_MENU_ID = '8c7d7561-a00c-11ea-be50-525400080321';

export const TITLE = 'orientuj się';
export const ADRESS = 'ŚWIĘTEGO MIKOŁAJA 14, 50-128 WROCŁAW';
export const PHONE_NUMBER = 500656556;
export const GOOGLE_MAPS =
  'https://www.google.com/maps/place/Orientuj+Si%C4%99/@51.1110967,17.0134633,14.35z/data=!4m16!1m10!4m9!1m1!4e2!1m6!1m2!1s0x470fc2754d2cd639:0x850022cc885da5f4!2sorientuj+si%C4%99+wroc%C5%82aw!2m2!1d17.0281139!2d51.1116016!3m4!1s0x470fc2754d2cd639:0x850022cc885da5f4!8m2!3d51.1116016!4d17.0281139';

export const NAV_SLIDE_TIME = '500ms';

export const NAV_ITEMS = [
  { to: '/onas', label: 'aboutUs' },
  { to: '/menu', label: 'menu' },
  { to: '/kontakt', label: 'contact' },
];

export const MENU_SECTIONS = [
  'panko',
  'soup',
  'meat',
  'noodles',
  'rice',
  'sea',
  'forKids',
  'sauce',
  'drinks',
];

export const WORKING_HOURS = {
  monday: { start: '11:30', end: '22:00' },
  tuesday: { start: '11:30', end: '22:00' },
  wednesday: { start: '11:30', end: '22:00' },
  thursday: { start: '11:30', end: '22:00' },
  friday: { start: '11:30', end: '00:00' },
  saturday: { start: '13:00', end: '00:00' },
  sunday: { start: '13:00', end: '22:00' },
};
