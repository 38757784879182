/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const constants = require('./src/constants');

exports.onInitialClientRender = () => {
  window.upmenuSettings = {
    id: constants.UP_MENU_ID,
  };
};
